import React from 'react';
import { Text, Button } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import actions from 'components/cms/dataTypes/Button/actions';
import messages from './messages';

const logObject = ({ componentType }, name, link) => ({
  action: 'click',
  type: componentType,
  step: 'finish',
  start: 1,
  finish: 1,
  link,
  gtm: {
    tool: {
      name,
    },
  },
});

const defaultImpReason = {
  title: ({ reason, colorType }) => (
    <Text size="l" weight="extrabold" colorType={colorType} marginBottom="m">
      <FormattedMessageMarkdown {...messages[reason].title} />
    </Text>
  ),
  button1: (props) => {
    const {
      reason,
      colorType,
      propsBag: { logger },
    } = props;
    return (
      <Button
        rounded
        arrow={false}
        uppercase={false}
        size="m"
        style={{ padding: '12px 24px', alignSelf: 'flex-start' }}
        marginTop="l"
        marginBottom="s"
        colorType={colorType}
        onClick={() => {
          const link = 'https://discover.certilogo.com/pages/support';
          logger(logObject(props, 'CTAbutton_agTag_helpButton', link));
          window.open(link);
        }}
      >
        <FormattedMessageMarkdown {...messages[reason].button1} />
      </Button>
    );
  },
  button2: (props) => {
    const {
      reason,
      colorType,
      propsBag: { logger },
    } = props;
    return (
      <Button
        rounded
        arrow={false}
        uppercase={false}
        size="m"
        style={{
          padding: '12px 24px',
          alignSelf: 'flex-start',
          border: '1px solid #0968F6',
          color: '#0968F6',
        }}
        buttonType="transparent"
        marginBottom="s"
        colorType={colorType}
        onClick={() => {
          const link = 'https://www.ebay.com/authenticity-guarantee';
          logger(logObject(props, 'CTAbutton_agTag_autenticityGuaranteeButton', link));
          window.open(link);
        }}
      >
        <FormattedMessageMarkdown {...messages[reason].button2} />
      </Button>
    );
  },
};

export default {
  imp_ag_invalid_screen: {
    ...defaultImpReason,
    text: ({ reason, colorType }) => (
      <Text size="m" colorType={colorType} marginBottom="m">
        <FormattedMessageMarkdown {...messages[reason].text} />
      </Text>
    ),
  },
  imp_ag_not_active: defaultImpReason,
  imp_ag_stolen: defaultImpReason,
  imp_ag_blocked: defaultImpReason,
  imp_ag_validate: defaultImpReason,
  imp_ag_mint: defaultImpReason,
  imp_ag_no_pictures: {
    ...defaultImpReason,
    subtitle: null,
    text: ({ reason, colorType }) => (
      <Text size="m" colorType={colorType} marginBottom="l">
        <FormattedMessageMarkdown {...messages[reason].text} />
      </Text>
    ),
    button1: (props) => {
      const {
        reason,
        colorType,
        propsBag: { logger },
        propsBag,
      } = props;
      return (
        <Button
          rounded
          arrow={false}
          uppercase={false}
          size="m"
          style={{ padding: '12px 24px', alignSelf: 'flex-start' }}
          marginBottom="s"
          colorType={colorType}
          onClick={() => {
            const link = 'start_authentication';
            logger(logObject(props, 'CTAbutton_agTag_retryButton', link));
            actions.makeAction(link, { propsBag });
          }}
        >
          <FormattedMessageMarkdown {...messages[reason].button1} />
        </Button>
      );
    },
    button2: (props) => {
      const {
        reason,
        colorType,
        propsBag: { logger },
      } = props;
      return (
        <Button
          rounded
          arrow={false}
          uppercase={false}
          size="m"
          style={{
            padding: '12px 24px',
            alignSelf: 'flex-start',
            border: '1px solid #0968F6',
            color: '#0968F6',
          }}
          buttonType="transparent"
          marginBottom="s"
          colorType={colorType}
          onClick={() => {
            const link = 'https://discover.certilogo.com/pages/support';
            logger(logObject(props, 'CTAbutton_agTag_helpButton', link));
            window.open(link);
          }}
        >
          <FormattedMessageMarkdown {...messages[reason].button2} />
        </Button>
      );
    },
    button3: (props) => {
      const {
        reason,
        colorType,
        propsBag: { logger },
      } = props;
      return (
        <Button
          rounded
          arrow={false}
          uppercase={false}
          size="m"
          style={{
            padding: '12px 24px',
            alignSelf: 'flex-start',
            border: '1px solid #0968F6',
            color: '#0968F6',
          }}
          buttonType="transparent"
          marginBottom="s"
          colorType={colorType}
          onClick={() => {
            const link = 'https://www.ebay.com/authenticity-guarantee';
            logger(logObject(props, 'CTAbutton_agTag_autenticityGuaranteeButton', link));
            window.open(link);
          }}
        >
          <FormattedMessageMarkdown {...messages[reason].button3} />
        </Button>
      );
    },
  },
};
